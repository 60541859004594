import React from "react"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import { useStaticQuery, graphql } from "gatsby"
import Page from "../templates/Page"

export default function Index() {
  const { background } = useStaticQuery(graphql`
    {
      background: file(relativePath: { eq: "supernova.jpeg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  const backgroundImage = getImage(background)

  return (
    <Page>
      <BgImage image={backgroundImage}>
        <div style={{ height: "100vh", width: "100vw" }}></div>
      </BgImage>
    </Page>
  )
}
